/* eslint-disable space-before-function-paren */
import { HTTP, DefaultErrorHandler } from "@/utils/http-common"

export default {
  upload: function($, file, projectId) {
    return new Promise((resolve, reject) => {
      var formData = new FormData()
      formData.append("file", file)
      formData.append("project_id", projectId)

      HTTP.post("/files/manage/", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(response => resolve(response.data))
        .catch(err => {
          $.$store.commit("error", {
            msg: "Vous n'avez pas la permission d'effectuer cette action.",
            color: "error"
          });
          reject(err);
        })
    })
  },
  getAll ($, projectId) {
    return new Promise((resolve, reject) => {
      HTTP.get("/bytel/events/")
        .then(r => {
          resolve(r.data)
        })
        .catch(err => DefaultErrorHandler($, err, reject))
    })
  },
  download ($, file) {
    return new Promise((resolve, reject) => {
      HTTP.get("/bytel/event/" + file.id + "/", {
        responseType: "arraybuffer"
      })
        .then(r => {
          const blob = new Blob([r.data], { type: r.headers["content-type"] });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          resolve(link);
        })
        .catch(err => DefaultErrorHandler($, err, reject))
    })
  }
}