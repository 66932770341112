<template>
  <div class="mt-0 pd-0">
    <v-text-field
      v-model="password"
      :type="hidden ? 'password' : 'text'"
      autocomplete="off"
      :placeholder="placeholder"
      :error-messages="errors.password"
      data-vv-name="password"
      required
      :append-icon="hidden ? 'mdi-eye' : 'mdi-eye-off'"
      @keyup.enter="enterkeyup"
      @click:append="hidden = !hidden"
      @blur="$v.password.$touch()"
      :name="name"
    />
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import { isComplexPassword } from "@/utils/regex_validator";

export default {
  name: "PasswordField",
  mixins: [validationMixin],
  validations: {
    password: {
      required,
      minLength: minLength(8) ,
      isComplexPassword,
      sameAsNew: sameAs(function () {
        if (this.sameAs) {
          return this.sameAs;
        } else {
          return this.password;
        }
      }),
    },
  },
  data: function () {
    return {
      password: "",
      hidden: true,
    };
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    complexityRequirements: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Mot de passe",
    },
    name: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Mot de passe",
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    sameAs: {
      type: String,
      default: null,
    },
  },
  methods: {
    enterkeyup(event) {
      this.$emit("keyup", event);
    },
  },
  mounted() {
    this.value = this.password;
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        this.password = newVal;
      },
    },
    password: {
      handler(newVal, oldVal) {
        this.password = newVal;
        this.$emit("input", this.password);
      },
    },
  },
  computed: {
    errors() {
      const errors = { password: [] };
      if (this.$v.password.$dirty) {
        !this.$v.password.required &&
          errors.password.push("Le mot de passe est requis.");

        this.complexityRequirements &&
          !this.$v.password.isComplexPassword &&
          errors.password.push(
            "Le mot de passe doit au moins être composer de 8 caractères, 1 minuscule, 1 majuscule, 1 chiffre, 1 caractère spécial"
          );

        this.sameAs &&
          !this.$v.password.sameAsNew &&
          errors.password.push("Les mots de passe doivent être identiques.");
      }
      return errors;
    },
  },
};
</script>

<style scoped></style>
