import { HTTP } from "@/utils/http-common";
import Constant from "@/constant";

export default {
  get: function($, query) {
    return new Promise((resolve, reject) => {
      HTTP.get(`query/manage/${query}/`)
        .then(response => resolve(response.data))
        .catch(err => {
          return reject(err);
        });
    });
  },
  run: function($, query, parameters = []) {
    return new Promise((resolve, reject) => {
      HTTP.get(`query/manage/${query}/results/`, {
        params: {
          parameters: parameters
        }
      })
        .then(response => resolve(response.data))
        .catch(err => {
          return reject(err);
        });
    });
  },
  serialNumber: function($, query, parameters = []) {
    return new Promise((resolve, reject) => {
      HTTP.post("bytel/serialnumber/", {
        params: {
          parameters: parameters
        }
      })
        .then(response => resolve(response.data))
        .catch(err => {
          return reject(err);
        });
    });
  },
  runAnon($ = null, query = "", parameters = []) {
    return new Promise((resolve, reject) => {
      HTTP.post("query/results/", {
        project_id: Constant.project,
        query: query,
        parameters: parameters
      })
        .then(response => resolve(response.data))
        .catch(err => reject(err));
    });
  }
};
