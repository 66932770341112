import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#0055A4',
                secondary: '#1480CF',
                tertiary: '#495057',
                accent: '#eb4952',
                error: '#f55a4e',
                info: '#0E264A',
                success: '#5cb860',
                warning: '#ffa21a',
            },
        },
    },
    icons: {
        iconfont: 'mdi',
    },
})
