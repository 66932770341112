<template>
  <v-container>
    <!-- Compteur -->
    <h2 class="primary--text">
      <v-icon left class="primary--text">mdi-chart-bar</v-icon>
      Indicateurs du mois en cours
    </h2>
    <v-row class="py-2">
      <v-col class="xs" cols="12" sm="6" md="3">
        <hmd-counter
          class="mb-0"
          label="Coût de la prestation"
          value="total"
          value-type="currency"
          query-name="depense_total_last_month"
          :last-row="true"
        />
      </v-col>
      <v-col class="xs" cols="12" sm="6" md="3">
        <hmd-counter
          class="mb-0"
          label="Dont coûts de transport"
          value="transport"
          value-type="currency"
          query-name="depense_transport_last_month"
          :last-row="true"
          sm="6"
          md="3"
        />
      </v-col>
      <v-col class="xs" cols="12" sm="6" md="3">
        <hmd-counter
          class="mb-0"
          label="Nombre de matériels reçus"
          value="Total reçu"
          value-type=""
          query-name="materiel_recu_last_month"
          :last-row="true"
          sm="6"
          md="3"
        />
      </v-col>
      <v-col class="xs" cols="12" sm="6" md="3">
        <hmd-counter
          class="mb-0"
          label="Montant des CPEs rachetés"
          value="Montant CPEs Rachetes"
          value-type="currency"
          query-name="amount_cpes_purchased_last_month"
          :last-row="true"
          sm="6"
          md="3"
        />
      </v-col>
    </v-row>
    <v-row class="my-5">
        <h2 class="mt-3">
          Analytique sur les
        </h2>
      <v-col class="d-flex" cols="12" sm="3">
        <period-chooser class="ma-0 pa-0 mt-1 ml-3" v-model="period" />
      </v-col>
    </v-row>
    <v-row class="py-2">
      <v-col class="xs" cols="12" sm="12" md="6" lg="6">
        <hmd-table-chart
          class="actualiser"
          title="Total des CPEs reçus"
          format-type="numbers"
          query-name="cpe_all"
          :parameters="queryParameters"
          groupBy="activity"
          x-axis="date"
          y-axis="total"
          chart-type="line"
          main-column="CPEs reçus"
        />
      </v-col>
      <v-col class="xs" cols="12" sm="12" md="6" lg="6">
        <hmd-table-chart
          class="actualiser"
          title="Destination des CPEs reçus"
          format-type="numbers"
          query-name="repartition"
          :parameters="queryParameters"
          x-axis="etat_sortie"
          y-axis="Total"
          chart-type="pie"
        />
      </v-col>
      <v-col class="xs" cols="12" sm="12" md="6" lg="6">
        <hmd-table-chart
          class="actualiser"
          format-type="currency"
          title="Total des recettes pour BTE"
          query-name="recettes"
          :parameters="queryParameters"
          x-axis="date"
          y-axis="montant"
          :ignored-columns="['primary_key']"
          chart-type="bar"
        />
      </v-col>
      <v-col class="xs" cols="12" sm="12" md="6" lg="6">
        <hmd-table-chart
          class="actualiser"
          format-type="currency"
          title="Total des coûts"
          query-name="depense"
          :parameters="queryParameters"
          x-axis="date"
          y-axis="total"
          chart-type="bar"
        />
      </v-col>
      
    </v-row>
  </v-container>
</template>

<script>
import Counter from "@/components/bnc/dashboard/Counter";
import TableChart from "@/components/bnc/dashboard/TableChart";
import Table from "@/components/bnc/dashboard/Table";
import Chart from "@/components/bnc/dashboard/Chart";
import PeriodChooser from "@/components/hmd/PeriodChooser.vue";
import Query from "@/query";

export default {
  components: {
    "hmd-counter": Counter,
    "hmd-table-chart": TableChart,
    "hmd-table": Table,
    "hmd-chart": Chart,
    "period-chooser": PeriodChooser
  },
  data() {
    return {
      period: "12 Month"
    };
  },
  computed: {
    queryParameters() {
      const parameters = [];
      parameters.push({
        name: "interval",
        type: "Text",
        value: this.period
      });
      return parameters;
    }
  }
};
</script>
