export default {
  datasetFacturation: {
    name: "FACTURATION_BTE",
    id: "7e7d1633-9700-445c-b5ca-75e85daf7cc8"
  },
  datasetFacturationAddedColumn: {
    name: "facturation_added_column",
    id: "ccdd2611-570a-486c-b3e4-8c43141dd208"
  },
  datasetPrixUnitaire: {
    name: "facturation_prix_unitaire",
    id: "931ed403-734f-4363-8051-57ad1550d0a3"
  },
  datasetFacturationHistorique: {
    name: "facturation_historique",
    id: "21c73176-6435-4710-b316-5c3f01b6d60e"
  },
  datasetEnlevement: {
    name: "enlevement",
    id: "cfc0e2a5-c5a9-4d7b-92dc-4965d9ef7ca5"
  },
  datasetLigEnlevement: {
    name: "LIG_ENLEVEMENT",
    id: "0c86de04-74ad-498e-b8cd-a61e1982a1da"
  },
  datasetMailing_list:{
    name: "mailing_list",
    id: "4e902d73-ccd7-4f9a-98a9-ffafefa8c9c8"
  },
  datasetArticleBT: {
    name: "article_bt",
    id: "a5b51681-fc1a-4052-bab3-31a8f58c5060"
  },
  datasetRecettes: {
    name: "recettes",
    id: "d14bfc20-06e5-4531-9799-1b3119daffa0"
  },
  query_recettes: {
    name: "recettes",
    id: "d14bfc20-06e5-4531-9799-1b3119daffa0"
  },
  query_facturation: {
    name: "facturation",
    id: "c299beef-ed1d-4b52-a8e4-c776b82767fc"
  },
  queryHeadersFacturation: {
    name: "headersFacturation",
    id: "43c3f19c-2347-4243-b15c-f1d9d4f40532"
  },
  company: "bouygues-telecom",
  triggerCronDatasetFacturationBTEPK: 8,
  project: process.env.VUE_APP_PROJECT_ID,
  lecteur_bouygue_groupname: "BTE-Utilisateur",
  admin_bouygue_groupname: "BTE-Admin",
  admin_infonegoce_groupname: "Infonegoce-Admin",
  lecteur_infonegoce_groupname: "Infonegoce-Utilisateur",
  admin_groups: function() {
    return [this.admin_bouygue_groupname, this.admin_infonegoce_groupname];
  },
  groups: function() {
    return [
      this.admin_bouygue_groupname,
      this.admin_infonegoce_groupname,
      this.lecteur_bouygue_groupname,
      this.lecteur_infonegoce_groupname
    ];
  },
  fetch_users_from_group(group) {
    switch (group) {
      case this.admin_bouygue_groupname:
      return [this.admin_bouygue_groupname, this.lecteur_bouygue_groupname];
      case this.admin_infonegoce_groupname:
        return [
          this.admin_infonegoce_groupname,
          this.lecteur_infonegoce_groupname,
          this.admin_bouygue_groupname,
          this.lecteur_bouygue_groupname
        ];
      default:
        return null;
    }
  },
  DEBUG: false
};
