<template>
  <v-container>
    <v-col>
      <v-row>
        <v-text-field
          v-model="recherche"
          placeholder="Recherche"
          append-icon="mdi-magnify"
        />
      </v-row>
      <v-row class="xs">
        <hmd-table
          ref="tableReference"
          title="Référence"
          :recherche="recherche"
          card
          paginate
          :conditional-format="[
            {
              cols: ['reference'],
              text: 'Référence',
              condition: v => v > 40,
              style: {}
            },
            {
              cols: ['destination'],
              text: 'Destination',
              condition: v => v > 40,
              style: {}
            },
            {
              cols: ['libelle'],
              text: 'Désignation',
              condition: v => v > 40,
              style: {}
            },
            {
              cols: ['destination frd', 'destination_frd'],
              text: 'Destination frd',
              condition: v => v > 40,
              style: {}
            },
            {
              cols: ['gencode'],
              text: 'Gencode',
              condition: v => v > 40,
              style: {}
            },
            {
              cols: ['qte a renvoyer', 'qte_a_renvoyer'],
              text: 'Quantité',
              condition: v => v > 40,
              style: {}
            }
          ]"
          :dataset="datasets.article_bt"
          :ignored-columns="ignoredColumns"
          :iseditable="modification"
          @modification="modificationEvent"
        />
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
  import Table from '@/components/bnc/dashboard/Table'
  import Constant from "@/constant"

  export default {
    components: {
      'hmd-table': Table,
    },
    data () {
      return {
        datasets: {
          mailing_list: Constant.datasetMailing_list.id,
          article_bt: Constant.datasetArticleBT.id,
        },
        ignoredColumns: ['primary_key', 'echangeable', 'a_tester_resil', 'prix_swap', 'upgrade_ram', 'reference_flash',
          'prix_alim', 'reparation', 'cable', 'swap_par', 'alim_incluse', 'carte', 'idcable1', 'idcable2', 'idcable3',
          'idcable4', 'idcable5', 'prix_vente', 'poids', 'ref_bt', 'valeur_bytel'],
        recherche: '',
        modification: false,
      }
    },
    methods: {
      modificationEvent (event) {
        this.modification = event
      },
    },
    beforeRouteLeave (to, from, next) {
      const message = 'Vous êtes en pleine modification, si vous quittez maintenant vos modifications ne seront pas sauvegardés. Souhaitez-vous sauvegarder avant de continuer ?'
      if (this.modification) {
        this.$store.commit('confirm', {
          message: message,
          title: 'Modification en attente',
          color: 'warning',
          onCancel: () => {
            next()
          },
          onConfirm: () => {
            this.$refs.tableReference.enableModification()
          },
        })
      } else {
        next()
      }
    },

  }
</script>

<style>

</style>
