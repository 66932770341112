<template>
    <v-select
      class="ma-0 pa-0"
      v-model="defaultPeriod"
      :items="periods"
      prepend-icon="mdi-calendar"
      color="primary"
      @change="changePeriod"
    >
    </v-select>
</template>

<script>
  import DateRangePicker from "@/components/hmd/DateRangePicker.vue"

  export default {
    components: {
      DateRangePicker
    },
    props: {
      value: {
        type: String,
        default: "24 Month"
      }
    },
    data () {
      return {
        defaultPeriod: "12 Derniers mois",
        periods: ["2 Derniers mois", "6 Derniers mois", "12 Derniers mois", "24 Derniers mois"],
        selected: `${this.value.split(" ")[0]} dernier mois`
      }
    },
    methods:{
      changePeriod (selectObj) {
        let nbMonths = 1
        if(!isNaN(this.defaultPeriod.split(" ")[0]))
          nbMonths = this.defaultPeriod.split(" ")[0]
          if (nbMonths <= 2) nbMonths=1
        
        this.$emit("input", `${nbMonths} Month`)
      }
    }
  }
</script>

