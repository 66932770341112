<template>
  <v-container>
    <div>
      <v-card class="elevation-2">
        <v-card-title>Formulaire recherche d'un S/N</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                dense
                label="Nom du client"
                v-model="dataFilters.client"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                dense
                label="OU Numéro RMA"
                v-model="dataFilters.rma"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                dense
                label="OU Numéro de série"
                v-model="dataFilters.numero_serie_recu"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                dense
                label="OU IMEI"
                v-model="dataFilters.imei_recu"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                dense
                label="OU Ticket"
                v-model="dataFilters.ticket"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                dense
                label="OU MSISDN"
                v-model="dataFilters.lien_client"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="accent" @click="reset">
            Effacer
          </v-btn>
          <v-btn
            color="primary"
            class="text-right"
            @click="filter"
            :loading="loading_search"
            >Rechercher</v-btn
          >
        </v-card-actions>
      </v-card>

      <v-row class="mt-3">
        <v-col col="6">
          <div v-if="dataFiltered.length <= 100">
            Nombre de résultats : {{ dataFiltered.length }}
          </div>
          <v-tooltip bottom v-if="dataFiltered.length > 100">
            <template v-slot:activator="{ on, attrs }">
              <div style="color: #eb4952;" v-on="on">
                Nombre de résultats : {{ dataFiltered.length }}
                <v-icon color="accent">mdi-alert</v-icon>
              </div>
            </template>
            <span
              >Les résultats ne sont affichés que s'il y a moins de 100
              résultats</span
            >
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row v-if="dataFiltered.length <= 100">
        <v-col
          v-for="(result, index) in dataFiltered"
          :key="index"
          cols="12"
          sm="4"
          md="4"
          lg="3"
        >
          <v-hover v-slot="{ hover }">
            <v-card
              @click="
                selected = result;
                $vuetify.goTo('#timeline');
              "
              :elevation="hover ? 4 : 1"
            >
              <v-card-title>
                <v-icon color="grey" class="mr-2">mdi-folder</v-icon>
                {{ result.reference_recu }}
              </v-card-title>
              <v-card-text>
                <v-text-field
                  v-if="result.rma"
                  disabled
                  label="RMA"
                  :value="result.rma"
                ></v-text-field>
                <v-text-field
                  v-if="result.imei_recu"
                  disabled
                  label="imei_recu"
                  :value="result.imei_recu"
                ></v-text-field>
                <v-text-field
                  v-if="result.ticket"
                  disabled
                  label="Ticket"
                  :value="result.ticket"
                ></v-text-field>
                <v-text-field
                  v-if="result.client"
                  disabled
                  label="Client"
                  :value="result.client"
                ></v-text-field>
                <v-text-field
                  v-if="result.numero_serie_recu"
                  disabled
                  label="Numéro de série"
                  :value="result.numero_serie_recu"
                ></v-text-field>
                <v-text-field
                  v-if="result.lien_client"
                  disabled
                  label="MSISDN"
                  :value="result.lien_client"
                />
                <v-text-field
                  v-if="result.date_recu"
                  disabled
                  label="Date reçu"
                  :value="result.date_recu"
                />
              </v-card-text>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
      <v-row id="timeline">
        <v-container v-if="selected">
          <h2>{{ selected.reference_recu }}</h2>
          <v-divider />
          <v-timeline dense max-width="fit-content">
            <!-- Transport -->
            <v-timeline-item
              v-if="selected.date_envoi_bt_chrone"
              color="#FF9F00"
              width="fit-content"
              fill-dot
            >
              <v-card>
                <v-card-title class="title transport white--text">
                  Transport
                </v-card-title>
                <v-card-text class="mt-3">
                  Date transport : {{ selected["date_envoi_bt_chrone"] }}<br />
                  BT Chrono : {{ selected["num_bt_chrono"] }}<br />
                  Date intégration PIG : {{ selected["date_integration_pig"] }}
                </v-card-text>
              </v-card>
            </v-timeline-item>
            <!-- Expedition -->
            <v-timeline-item
              v-if="selected.date_expedition"
              width="fit-content"
              color="#820263"
              fill-dot
            >
              <v-card>
                <v-card-title class="title expedition white--text">
                  Expédition
                </v-card-title>
                <v-card-text class="mt-3">
                  Date expédition : {{ selected["date_expedition"] }}<br />
                  Référence sortante : {{ selected["reference_sortant"] }}<br />
                  Etat de sortie : {{ selected["etat_sortie"] }}<br />
                  Client : {{ selected["client"] }}
                </v-card-text>
              </v-card>
            </v-timeline-item>
            <!-- Tester -->
            <v-timeline-item
              v-if="selected.a_tester_resil"
              color="#EB4952"
              width="fit-content"
              fill-dot
            >
              <v-card>
                <v-card-title class="title test white--text">
                  Test
                </v-card-title>
                <v-card-text class="mt-3">
                  Doit etre tester : {{ selected["a_tester_resil"] }}<br />
                  Etat après test : {{ selected["etat_apres_test"] }}
                </v-card-text>
              </v-card>
            </v-timeline-item>
            <!-- Reparation 3 -->
            <v-timeline-item
              v-if="selected.date_retour_reparateur3"
              color="#A6DDED"
              width="fit-content"
              fill-dot
            >
              <v-card>
                <v-card-title class="title reparation3 white--text">
                  Réparation 3
                </v-card-title>
                <v-card-text class="mt-3">
                  Date de réparation : {{ selected["date_retour_reparateur3"] }}
                </v-card-text>
              </v-card>
            </v-timeline-item>
            <!-- Reparation 2 -->
            <v-timeline-item
              v-if="selected.date_retour_reparateur2"
              color="#ff9933"
              width="fit-content"
              fill-dot
            >
              <v-card>
                <v-card-title class="title reperation2 white--text">
                  Réparation 2
                </v-card-title>
                <v-card-text class="mt-3">
                  Date de réparation : {{ selected["date_retour_reparateur2"] }}
                </v-card-text>
              </v-card>
            </v-timeline-item>
            <!-- Reparation 1 -->
            <v-timeline-item
              v-if="selected.date_retour_reparateur1"
              color="#4EA699"
              width="fit-content"
              fill-dot
            >
              <v-card>
                <v-card-title class="title reparation1 white--text">
                  Réparation 1
                </v-card-title>
                <v-card-text class="mt-3">
                  Date de réparation : {{ selected["date_retour_reparateur1"] }}
                </v-card-text>
              </v-card>
            </v-timeline-item>
            <!-- Recu -->
            <v-timeline-item
              v-if="selected.date_recu"
              color="#009DCC"
              width="fit-content"
              fill-dot
            >
              <v-card>
                <v-card-title class="title recu white--text">
                  Reçu
                </v-card-title>
                <v-card-text class="mt-3">
                  Date reçu : {{ selected["date_recu"] }}<br />
                  Référence reçue : {{ selected["reference_recu"] }}
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-container>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import QueryService from "@/services/QueryService.js";

export default {
  data() {
    return {
      data: {
        headers: [],
        rows: []
      },
      dataFilters: {
        client: "",
        numero_serie_recu: "",
        imei_recu: "",
        ticket: "",
        rma: "",
        lien_client: ""
      },
      dataFiltered: [],
      selected: null,
      loading_search: false
    };
  },
  methods: {
    filter() {
      
      this.selected = null;
      this.loading_search = true;
      this.search();
      
    },
    search() {
      // Get data for all references
      QueryService.serialNumber(this, "serialnumber", this.dataFilters).then(response => {
        
        this.data.headers = response.data.columns;
        this.dataFiltered = response.data.rows;
        
      });
      this.loading_search = false;
    },
    reset() {
      this.selected = null;
      this.dataFiltered = [];
      this.dataFilters.client = "";
      this.dataFilters.numero_serie_recu = "";
      this.dataFilters.imei_recu = "";
      this.dataFilters.ticket = "";
      this.dataFilters.rma = "";
      this.dataFilters.lien_client = "";
    }
  }
};
</script>

<style lang="stylus" scoped>
recu = #009DCC
transport = #FF9F00
expedition_color = #820263
test = #EB4952
reparateur1 = #4EA699
reparateur2 = #ff9933
reparateur3 = #A6DDED

my-white = #FAFAFA
</style>

<style lang="scss" scoped>
$recu: #009dcc;
$transport: #ff9f00;
$expedition: #820263;
$test: #eb4952;
$reparateur1: #4ea699;
$reparateur2: #ff9933;
$reparateur3: #a6dded;

$my-white: #fafafa;

html {
  scroll-behavior: smooth;
}

ul {
  list-style: none;
}

.recu {
  color: $recu;
  background-color: $recu;
}

.transport {
  color: $transport;
  background-color: $transport;
}

.expedition {
  color: $expedition;
  background-color: $expedition;
}

.test {
  color: $test;
  background-color: $test;
}

.reparateur1 {
  color: $reparateur1;
  background-color: $reparateur1;
}

.reparateur2 {
  color: $reparateur2;
  background-color: $reparateur2;
}

.reparateur3 {
  color: $reparateur3;
  background-color: $reparateur3;
}

.theme--light.v-card > .v-card__text,
.theme--light.v-card .v-card__subtitle {
  color: rgba(0, 0, 0, 0.85);
}
</style>
