<template>
  <v-container fluid>
    <v-tabs v-model="tab">
      <v-tab>Profil</v-tab>
      <v-tab v-if="isAdmin">Utilisateur</v-tab>
      <v-tab v-if="isAdmin">Groupe</v-tab>
    </v-tabs>
    <v-tabs-items class="elevation-1" v-model="tab">
      <v-tab-item>
        <v-row>
          <v-col cols="12" md="6" sm="12">
            <v-card class="elevation-0">
              <v-card-title class="justify-center"
                >Informations personnelles
              </v-card-title>
              <v-card-text>
                <v-row class="justify-center">
                  <v-col cols="8">
                    <v-text-field
                      outlined
                      disabled
                      label="E-mail"
                      :value="profile.email"
                    />
                    <v-text-field
                      v-model="profile.last_name"
                      outlined
                      label="Prénom"
                    />
                    <v-text-field
                      v-model="profile.first_name"
                      outlined
                      label="Nom"
                    />
                  </v-col>
                </v-row>
                <v-row class="justify-center">
                  <v-col cols="6">
                    <v-btn
                      block
                      color="accent"
                      :disabled="!hasChanged"
                      @click="updateProfileInfo"
                    >
                      Mettre a jour
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <v-card class="elevation-0">
              <v-card-title class="justify-center">Mot de passe</v-card-title>
              <v-card-text>
                <v-row class="justify-center">
                  <v-col cols="8">
                    <password-field
                      v-model="password.old"
                      label="Ancien mot de passe"
                      outlined
                    />
                    <password-field
                      v-model="password.new"
                      outlined
                      label="Nouveau mot de passe"
                      complexity-requirements
                    />
                    <password-field
                      v-model="password.newRepeat"
                      :same-as="password.new"
                      outlined
                      label="Resaisir le nouveau mot de passe"
                    />
                  </v-col>
                </v-row>
                <v-row class="justify-center">
                  <v-col cols="6">
                    <v-btn block color="accent" @click="changePassword">
                      mettre a jour
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item v-if="isAdmin">
        <users-crud :authorized_groups="authorized_groups" />
      </v-tab-item>
      <v-tab-item v-if="isAdmin">
        <group-company-settings-table :authorized_groups="authorized_groups" />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import AccountService from "@/services/AccountService";
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import User from "@/views/admin/Users.vue";
import Constant from "@/constant";
import GroupCompanySettingsTable from "@/components/hmd/GroupCompanySettingsTable";
import UsersCrud from "@/components/hmd/Users/UsersCrud";
import { isComplexPassword } from "@/utils/regex_validator";
import PasswordField from "@/components/base/PasswordField";

export default {
  components: {
    PasswordField,
    UsersCrud,
    GroupCompanySettingsTable,
    User
  },
  mixins: [validationMixin],
  validations: {
    password: {
      old: { required },
      new: {
        required,
        isComplexPassword
      },
      newRepeat: {
        required,
        sameAsNew: sameAs(function() {
          return this.password.new;
        }),
        isComplexPassword
      }
    },
    validationGroup: ["password", "profile"]
  },
  data() {
    return {
      tab: 0,
      loading: false,

      profileCopy: {
        email: "",
        first_name: "",
        last_name: ""
      },
      profile: {
        email: "",
        first_name: "",
        last_name: ""
      },
      password: {
        old: "",
        new: "",
        newRepeat: ""
      },
      admins: Constant.admin_groups(),
      group: this.$store.getters.group,
      authorized_groups: Constant.fetch_users_from_group(
        this.$store.getters.group
      )
    };
  },
  computed: {
    passwordErrors() {
      const $ = this;
      const errs = {
        old: [],
        new: [],
        newRepeat: []
      };
      if ($.$v.password.old.$dirty) {
        !$.$v.password.old.required &&
          errs.old.push("L'ancien mot de passe est requis");
      }
      if ($.$v.password.new.$dirty) {
        !$.$v.password.new.required &&
          errs.new.push("Le nouveau mot de passe est requis");
        !$.$v.password.new.isComplexPassword &&
          errs.new.push(
            "Le mot de passe doit au moins être composer de 8 caractères, 1 minuscule, 1 majuscule, 1 chiffre, 1 caractère spécial"
          );
      }
      if ($.$v.password.newRepeat.$dirty) {
        if (!$.$v.password.newRepeat.required) {
          errs.newRepeat.push("Vous devez répétez votre mot de passe");
        }
        if (!$.$v.password.newRepeat.sameAsNew) {
          errs.newRepeat.push("Les mots de passe ne sont pas identiques");
        }
      }
      return errs;
    },
    hasChanged() {
      return !this.lo.isEqual(this.profileCopy, this.profile);
    },
    isAdmin() {
      return this.admins.includes(this.group);
    }
  },
  created() {
    var t = this;
    this.loading = true;

    AccountService.profile(this).then(function(response) {
      t.profile = response;
      t.profileCopy = t.lo.cloneDeep(response);
    })
    .finally(() => {
      t.loading = false
    })
  },
  methods: {
    updateProfileInfo() {
      const t = this;
      AccountService.updateProfileInfo(
        this,
        this.profile.first_name,
        this.profile.last_name,
        this.profile.email
      ).then(r => {
        t.profileCopy = t.lo.cloneDeep(t.profile);
      });
    },
    changePassword() {
      const $ = this;
      $.$v.password.$touch();
      if (!$.$v.password.$invalid) {
        AccountService.changePassword(
          $,
          $.password.old,
          $.password.new,
          $.password.newRepeat
        ).then(r => {
          $.password.old = "";
          $.password.new = "";
          $.password.newRepeat = "";
        });
      }
    }
  }
};
</script>
