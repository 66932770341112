<template>
  <v-app>
    <v-main>
      <v-container class="pa-0 fill-height" fluid>
        <v-row class="fill-height pa-0 ma-0">
          <v-col
            class="pos-relative  px-10"
            cols="12"
            md="6"
            sm="12"
            lg="4"
            xl="4"
          >
            <v-row class="fill-height" align="center" justify="center">
              <v-col cols="12">
                
                  <img
                    src="../assets/logo-bouygues-telecom-entreprises.png"
                     class="logo-bte"
                />
                <h1 class="thin my-5 ">
                  Mot de passe oublié
                </h1>
                  <password-field
                  v-model="$v.form.password1.$model"
                  placeholder="Veuillez entrer un mot de passe"
                  label="Mot de passe"
                  complexity-requirements
                />
                <password-field
                  v-model="$v.form.password2.$model"
                  placeholder="Veuillez confirmer votre mot de passe"
                  label="Répétez votre mot de passe"
                  :same-as="$v.form.password1.$model"
                  complexity-requirements
                />
                <v-container v-show="!isValidated">
                  <v-btn
                    class="my-3"
                    color="accent white--text"
                    :disabled="canSubmit"
                    @click="submitForm"
                    :loading="loading"
                    large
                    block
                    >Enregistrer le mot de passe
                  </v-btn>
                </v-container>
                <v-container v-show="1" class="slide-x-transition">
                  <p>
                      Si vous n'êtes pas redirigé veuillez 
                    <a @click="$router.push('/signin')" class="accent--text">cliquer ici.</a
                    >
                  </p>
                </v-container>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            class="bg-cover fill-height hidden-xs-only hidden-sm-only md-6 lg-8 xl-8"
          />
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import AccountService from "@/services/AccountService";
import { validationMixin } from "vuelidate";
import { required, sameAs } from "vuelidate/lib/validators";
import { isComplexPassword } from "@/utils/regex_validator";
import PasswordField from "@/components/base/PasswordField";

export default {
  components: { PasswordField },
  mixins: [validationMixin],
  validations: {
    form: {
      password1: {
        required,
        isComplexPassword
      },
      password2: {
        required,
        sameAs: sameAs(function() {
          return this.form.password1;
        }),
        isComplexPassword
      }
    }
  },
  data() {
    return {
      uid: this.$route.params.uid,
      key: this.$route.params.key,
      form: {
        password1: "",
        password2: ""
      },
      loading: false,
      isValidated: false
    };
  },
  methods: {
    submitForm() {
      this.loading = true;
      AccountService.sendNewPassword(
        this,
        this.form.password1,
        this.form.password2,
        this.uid,
        this.key
      )
        .then(() => {
          this.isValidated = true;
          setTimeout(() => {
            this.$router.push("/signin");
          }, 5000);
        })
        .catch(error => {
          this.$store.commit("error", error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  computed: {
    errors() {
      const errors = {
        password1: [],
        password2: []
      };

      this.$v.form.password1.$dirty &&
        !this.$v.form.password1.required &&
        errors.password1.push("Le mot de passe est requis");

      this.$v.form.password2.$dirty &&
        !this.$v.form.password2.required &&
        errors.password2.push("Le mot de passe est requis");

      this.$v.form.password1.$dirty &&
        !this.$v.form.password1.isComplexPassword &&
        errors.password1.push(
          "Le mot de passe doit au moins être composer de 8 caractères, 1 minuscule, 1 majuscule, 1 chiffre, 1 caractère spécial"
        );

      this.$v.form.password2.$dirty &&
        !this.$v.form.password2.isComplexPassword &&
        errors.password2.push(
          "Le mot de passe doit au moins être composer de 8 caractères, 1 minuscule, 1 majuscule, 1 chiffre, 1 caractère spécial"
        );

      this.$v.form.password2.$dirty &&
        !this.$v.form.password2.sameAs &&
        errors.password2.push("Les mots de passe ne sont pas identiques");

      return errors;
    },
    canSubmit() {
      return this.$v.form.$invalid || this.loading;
    }
  }
};
</script>
<style>

.pos-relative {
  position: relative;
}

.logo-bte {
  height: "160px";
  margin-left: -30px;
  max-width: 100%;
}

.bg-cover {
  background-size: cover;
  background-position: 50% 50%;
  background-image: url(../assets/bg-cover.jpg);
}
</style>
