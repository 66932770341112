<template>
  <v-card class="elevation-0">
      <v-row >
        <v-col justify="start">
          <v-card-title>Événements</v-card-title>
        </v-col>
        <v-col justify="end" class="text-right mt-3 mx-5">
          <upload-file-btn  @refresh="getFiles"/>
        </v-col>
      </v-row>
    <v-card-text class="d-flex justify-center">
      <v-expansion-panels inset multiple v-model="panel">
        <v-row v-if="events.length <= 0" class="mt-2 mx-1">
          Aucun fichier n'est disponible pour l'instant.
        </v-row>
        <v-expansion-panel v-for="(event, index) in events" :key="index">
          <v-expansion-panel-header class="subtitle-1">Événement du {{ event.uploaded_at }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row no-gutters>
              <v-col cols="10" sm="7" md="6">
                <v-row>
                  <v-col cols="12">
                    <v-icon left>mdi-file</v-icon>
                    Fichier uploadé par l'utilisateur <span class="primary--text">{{ event.uploaded_by }}</span>
                  </v-col>
                </v-row>
              </v-col>
              <v-divider v-show="!$vuetify.breakpoint.xsOnly" sm="1" md="2" class="mr-4" vertical/>
              <v-col cols="12" sm="4" :class="$vuetify.breakpoint.xsOnly ? 'mt-3' : ''"
                     class="align-self-center justify-center text-center d-flex flex-column"
              >
                <b>Télécharger le fichier : {{event.original_name}}
                </b>
                <v-btn block outlined class="mt-3" color="primary darken-1" width="fit-content" @click="getFile(event)"
                       :loading="loading" target="_blank"
                >
                  <v-icon left>mdi-download</v-icon>
                  Télécharger
                </v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
  import UploadFileBtn from "@/components/bytel/UploadFileBtn"
  import FileService from "@/services/FileService"
  
  export default {
    name: "EventExpandPanel",
    components: {
      UploadFileBtn
    },
    data: function () {
      return {
        loading: false,
        panel: [0],
        events: []
      }
    },
    mounted:function(){
      this.getFiles()
    },
    methods: {
      getFiles () {
        this.isLoading = true
        FileService.getAll(this)
          .then(r => {
            this.events = r.results
          })
          .finally(() => {
            this.isLoading = false
          })
      },
      getFile (event) {
        this.isLoading = true
        FileService.download(this, event)
          .then(link => {
            link.download = event.original_name;
            link.click();
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    }
  }
</script>

<style scoped>
</style>
